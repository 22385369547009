<template>
  <page-layout>
    <p>{{ info.name }} {{ info.lastname }}</p>
    <p>
      Taleon Select Kişilik Envanteri, iş dünyasında en çok kabul gören 30
      yetkinlikteki performansı etkileyen davranış eğilimleri ile ilgili bir
      özet rapor sunmaktadır. Bu rapor, Taleon Select Kişilik Envanterinde
      verilen yanıtlar esas alınarak hazırlanmıştır. Bu yanıtlar, kişinin
      davranış eğilimleri ile ilgili kendi yorumlarını içermektedir. Bu nedenle,
      raporun geçerliliği, verilen yanıtların doğruluğu ve kişisel farkındalık
      ile yakından alakalıdır. Dışarıdan gözlemlenebilen davranışlar olan
      yetkinlikler, kişilik eğilimlerinden başka bilgi, beceri, motivasyon,
      eğitim, deneyim ve içinde bulunulan kültür gibi birçok faktörden
      etkilenir. Taleon Select Kişilik Envanteri kullanılarak yapılan bu
      değerlendirme, bütünün sadece bir parçası olan kişilik özelliklerini baz
      aldığı unutulmamalıdır.
    </p>
    <div v-if="isLoading" id="chart">
      <apexchart
        type="bar"
        height="500px"
        :options="chartOptions"
        :series="series"
      />
      <div v-for="domain in results" :key="domain.id">
        <Domain :domain="domain" />
      </div>
    </div>
  </page-layout>
</template>

<script>
import axios from '@axios';
import PageLayout from '@/components/PageLayout.vue';
import Domain from '@/components/Domain.vue';
export default {
  name: 'Rapor',
  components: { PageLayout, Domain },
  data: () => ({
    results: [],
    info: {},
    series: [
      {
        name: 'You',
        data: []
      }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
      plotOptions: {
        bar: {
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      yaxis: {
        max: 120
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
            fontSize: '12px'
          }
        }
      }
    },
    caId: '',
    isLoading: false
  }),
  mounted() {
    this.caId = this.$route.params.caId;
    this.getResult();
  },
  methods: {
    getResult() {
      axios
        .get(`/generate-bigfive-pdf-candidate-result/${this.caId}`)
        .then((response) => {
          for (let i = 0; i < response.data.result.length; i++) {
            const el = response.data.result[i];
            this.chartOptions.xaxis.categories.push(el.title);
            this.series[0].data.push(el.score);
          }
          this.results = response.data.result;
          this.info = response.data.info;
          this.isLoading = true;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error('API Error.');
        });
    }
  }
};
</script>

<style lang="scss"></style>
