<template>
  <div>
    <a
      :id="domain.title.toLowerCase()"
      :href="'#' + domain.title.toLowerCase() || ''"
    >
      <span class="headline">
        {{ domain.title }}
      </span>
    </a>
    <p class="subheading grey--text text--darken-1">
      Score: {{ domain.score }} - {{ domain.scoreText }}
    </p>
    <p>{{ domain.shortDescription }}</p>
    <BarChart :data="domain.facets" />
    <div v-for="facet in domain.facets" :key="facet.title">
      <a
        :id="facet.title.toLowerCase()"
        :href="'#' + facet.title.toLowerCase()"
      >
        <span class="title">
          {{ facet.title }}
        </span></a
      >
      <p class="subheading grey--text text--darken-1">
        Score: {{ facet.score }} - {{ facet.scoreText }}
      </p>
      <p>{{ facet.text }}</p>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart.vue';

export default {
  name: 'Domain',
  components: {
    BarChart
  },
  props: {
    domain: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
